import React, { useEffect, useState } from "react";
import CommonDrawer from "../../common/CommonDrawer";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import {
  addIntakeAnswersFunc,
  resetUpdateAnswers,
  updateIntakeAnswersFunc,
} from "../../actions/ClientIntakeActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCaseDetailByCaseId } from "../../actions/Cases";

const EditIntakeAnswerDrawer = ({
  anchor,
  openEditIntakeAnswer,
  setOpenEditIntakeAnswer,
  onClose,
  allQuestions,
  questionsAnswers,
  caseId,
  clientId,
}) => {
  const [updatedAnswers, setUpdatedAnswers] = useState([]);

  const dispatch = useDispatch();

  const updateIntakeAnswers = useSelector((state) => state.updateIntakeAnswers);
  const {
    loading: loadingIntakeAnswers,
    error,
    updatedIntakeAnswers,
  } = updateIntakeAnswers;

  const addIntakeAnswers = useSelector((state) => state.addIntakeAnswers);
  const {
    loading: loadingAddAnswers,
    error: errorAddAnswers,
    intakeAnswers,
  } = addIntakeAnswers;

  useState(() => {
    const initialAnswers = allQuestions?.map((question) => {
      const existingAnswer = questionsAnswers.find(
        (answer) => answer.intake_question_id === question.id
      );

      return existingAnswer
        ? {
            id: existingAnswer?.intake_question_id,
            answerId: existingAnswer?.id,
            answer_text: existingAnswer.answer_text || "",
            existingAnswers: true,
          }
        : {
            id: question.id,
            answer_text: "",
            existingAnswers: false,
            questionText: question?.question_text,
          };
    });
    setUpdatedAnswers(initialAnswers);
  }, []);

  const answerChangeHandler = (questionId, answerText) => {
    setUpdatedAnswers((prevAnswers) => {
      const index = prevAnswers.findIndex((answer) => answer.id === questionId);
      const newAnswers = [...prevAnswers];
      if (index !== -1) {
        newAnswers[index] = { ...newAnswers[index], answer_text: answerText };
      } else {
        newAnswers.push({ id: questionId, answer_text: answerText });
      }
      return newAnswers;
    });
  };

  const saveIntakeAnswersHandler = () => {
    const filteredMultipleAnswers = updatedAnswers
      ?.filter((item) => item?.existingAnswers === false)
      ?.map(({ id, answer_text, questionText }) => ({
        intake_question_id: id,
        answer_text,
        question_text: questionText,
      }));

    const multipleAnswers = {
      client_id: clientId,
      case_id: caseId,
      answers: filteredMultipleAnswers,
    };

    const filteredExistingAnswers = updatedAnswers
      ?.filter((item) => item?.existingAnswers === true)
      ?.map(({ answerId, answer_text }) => ({ id: answerId, answer_text }));

    const answersObj = {
      answers: filteredExistingAnswers,
    };

    dispatch(updateIntakeAnswersFunc(answersObj));
    dispatch(addIntakeAnswersFunc(multipleAnswers));
  };

  useEffect(() => {
    if (updatedIntakeAnswers?.ok && intakeAnswers?.ok) {
      setOpenEditIntakeAnswer(false);
      dispatch(getCaseDetailByCaseId(caseId));
      dispatch(resetUpdateAnswers());
    }
  }, [updatedIntakeAnswers, intakeAnswers]);

  return (
    <CommonDrawer
      anchor={anchor}
      open={openEditIntakeAnswer}
      setOpen={setOpenEditIntakeAnswer}
      onClose={onClose}
      height="100%"
    >
      <Stack gap="24px" height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb="32px"
        >
          <Typography variant="h5">Edit Intake Information</Typography>

          <IconButton
            size="small"
            onClick={() => {
              setOpenEditIntakeAnswer(false);
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
        <Typography
          variant="subtitle1"
          fontSize="1rem"
          color="secondary"
          textTransform="uppercase"
        >
          Questions
        </Typography>
        {allQuestions?.map((item, index) => {
          return (
            <React.Fragment key={item?.id}>
              <TextField
                label={item?.question_text}
                value={
                  updatedAnswers?.find((answer) => answer.id === item?.id)
                    ?.answer_text || ""
                }
                onChange={(e) => answerChangeHandler(item?.id, e.target.value)}
              />
            </React.Fragment>
          );
        })}
      </Stack>
      <Stack alignItems="flex-end" pt={1.4}>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loadingIntakeAnswers || loadingAddAnswers}
          // disabled={!firstName || !email || emailError}
          // height="40px"
          // width="140px"
          onClick={saveIntakeAnswersHandler}
        >
          {" "}
          Save
        </LoadingButton>
      </Stack>
    </CommonDrawer>
  );
};

export default EditIntakeAnswerDrawer;
