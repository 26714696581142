import React from "react";

 
const EditIcon = ({ width = "17", height = "17", color = "black", opacity="0.38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M9.39156 2.54962L3.57614 8.70504C3.35656 8.93879 3.14406 9.39921 3.10156 9.71796L2.83948 12.013C2.74739 12.8417 3.34239 13.4084 4.16406 13.2667L6.44489 12.8771C6.76364 12.8205 7.20989 12.5867 7.42948 12.3459L13.2449 6.19046C14.2507 5.12796 14.7041 3.91671 13.1386 2.43629C11.5803 0.970041 10.3974 1.48712 9.39156 2.54962Z"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42188 3.57715C8.72646 5.53215 10.3131 7.02673 12.2823 7.22506"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.125 15.583H14.875"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
