import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  BillingIcon,
  HelpIcon,
  LogoutIcon,
  NotificationIcon,
  UserTagIcon,
} from "../../assets/constants";
import { useNavigate } from "react-router-dom";

import HeaderText from "../../common/HeaderText";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/Auth";
import {
  getAgentProfile,
  getAgencyDetailFunc,
  resetAgentsList,
} from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import Cookies from "js-cookie";
import NotificationDrawer from "../NotificationDrawer";
import {
  allNotificationsFunc,
  resetAlertNotifications,
  resetAllBodyParts,
  resetAllNotifications,
  resetChatNotifications,
  resetUserActivityNotifications,
  resetUserFeedNotifications,
} from "../../actions/AppConfig";
import {
  resetAllCases,
  resetCaseClientDocs,
  resetUnpaginatedCases,
} from "../../actions/Cases";
import { resetGetBillingSubscription } from "../../actions/BillingSubscription";

const page_size = 10;

const useStyles = makeStyles((theme) => {
  return {
    main__appbar: {
      background: "#fcfcfc !important",
      position: "fixed",
      top: "0",
      padding: "1rem",
      left: "auto",
      display: "flex",
      justifyContent: "space-between",
      zIndex: "100",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        left: "0",
      },
    },
    bellIcon: {
      color: "#555",
    },
    menu_item: {
      padding: "16px !important",
    },
  };
});

const NavbarUI = ({ drawerWidth }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  // const {userInfo} = useSelector((state) => state.userLogin);
  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading, error, agentUser } = agentProfile;

  const getAllNotifications = useSelector((state) => state.getAllNotifications);
  const {
    loading: loadingAllNotifications,
    error: errorAllNotifications,
    allNotifications,
  } = getAllNotifications;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  // const userInfo = localStorage.getItem('userInfo')

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    setAnchorEl(null);
    dispatch(logout());
    dispatch(resetUserFeedNotifications());
    dispatch(resetAllNotifications());
    dispatch(resetAllCases());
    dispatch(resetCaseClientDocs());
    dispatch(resetAllBodyParts());
    dispatch(resetUnpaginatedCases());
    dispatch(resetAgentsList());
    dispatch(resetGetBillingSubscription());
    navigate("/");
  };

  const navigateProfileHandler = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const navigateBillingHandler = () => {
    setAnchorEl(null);
    navigate("/settings?tab=billing");
  };

  useEffect(() => {
    dispatch(getAgentProfile());
    dispatch(getAgencyDetailFunc());
    dispatch(allNotificationsFunc(page, page_size));
  }, []);

  useEffect(() => {
    if (!loadingAllNotifications && allNotifications?.ok) {
      setTotalNotifications(allNotifications?.number_of_unread_notifications);
    }
  }, [allNotifications]);

  // const [user, setUser] = useState({});

  // const getUser = async (userInfo) => {
  //   const config = {
  //     headers: {
  //       "Content-type": "application/json",
  //       Authorization: `Bearer ${userInfo.token}`,
  //     },
  //   };
  //   const { data } = await axios.get(
  //     `https://staging-api.mycasecare.com/agent/get?agent_id=${userInfo.agent_id}`,
  //     config
  //   );

  //   // console.log("DATA NAVBAR", data);

  //   setUser(data?.agent);
  // };
  const agentToken = Cookies.get("token");

  // useEffect(() => {
  //   // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   // getUser(userInfo);
  //   // const cookieAgentId = Cookies.get("agentId");
  //   // const cookieAgencyId = Cookies.get("agencyId");

  //   if (agentToken) {
  //     dispatch(getAgentProfile());
  //   }
  // }, []);

  const helpLinkHandler = () => {
    window.open(
      "https://forms.office.com/r/ThWj8nerjG",
      "_blank"
    );
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "help",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const openNotificationsHandler = () => {
    setOpenNotificationDrawer((prevState) => !prevState);
    dispatch(resetAllNotifications());
    dispatch(resetChatNotifications());
    dispatch(resetAlertNotifications());
    dispatch(resetUserActivityNotifications());
  };

  return (
    <>
      <NotificationDrawer
        anchor="right"
        openNotificationDrawer={openNotificationDrawer}
        setOpenNotificationDrawer={setOpenNotificationDrawer}
        setTotalNotifications={setTotalNotifications}
        totalNotifications={totalNotifications}
        onClose={() => setOpenNotificationDrawer(false)}
      />

      <AppBar
        sx={{
          width: `calc(100% - ${drawerWidth})`,
        }}
        elevation={0}
        className={classes.main__appbar}
      >
        <Stack
          pr={3}
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip title="Help">
            <IconButton size="small" onClick={helpLinkHandler}>
              <HelpIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Notifications">
            <Badge
              color="primary"
              badgeContent={totalNotifications}
              sx={{
                "& .MuiBadge-badge": {
                  right: "4px",
                  top: 0,
                },
              }}
            >
              <IconButton size="small" onClick={openNotificationsHandler}>
                <NotificationIcon />
              </IconButton>
            </Badge>
          </Tooltip>

          <>
            <Stack sx={{ cursor: "pointer" }}>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableRipple
              >
                {/* <ProfilePicture
                  src={userProfile}
                  alt="user profile"
                  width="40px"
                  height="40px"
                /> */}
                <Avatar
                  alt="Profile Picture"
                  src={agentUser?.agent?.profile_picture_url}
                />
              </IconButton>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="16px"
                  padding="10px 0"
                >
                  {/* <ProfilePicture
                    src={userProfile}
                    alt="user profile"
                    width="69px"
                    height="69px"
                  /> */}
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    alt="Profile Picture"
                    src={agentUser?.agent?.profile_picture_url}
                  />

                  <Box display="flex" flexDirection="column" gap="1px">
                    <HeaderText
                      variant="h3"
                      fontSize="16px"
                      textContent={agentUser?.agent?.name}
                      textAlign="inherit"
                      color="#000"
                    />
                    <HeaderText
                      variant="h6"
                      fontSize="12px"
                      fontWeight="400"
                      textContent={agentUser?.agent?.email}
                      textAlign="inherit"
                    />
                  </Box>
                </Box>
              </MenuItem>

              <Divider />
              <MenuItem
                onClick={navigateBillingHandler}
                className={classes.menu_item}
              >
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <BillingIcon fontSize="small" />
                </ListItemIcon>
                Billing{" "}
              </MenuItem>
              <MenuItem
                onClick={navigateProfileHandler}
                className={classes.menu_item}
              >
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <UserTagIcon fontSize="small" />
                </ListItemIcon>
                User Account
              </MenuItem>
              <MenuItem onClick={logoutHandler} className={classes.menu_item}>
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        </Stack>
      </AppBar>
    </>
  );
};

export default NavbarUI;
